<template>
  <div class="dashboard-org">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>Manage Categories</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Edit Categories</v-toolbar>
            <v-card-title class="px-0">
              <v-container class="fluid d-lg-flex align-center">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="ml-auto mr-3"
                  dark
                  rounded
                  @click="dialog = true"
                >
                  + Add Category
                </v-btn>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="mb-3"
                ></v-text-field>
              </v-col>
              <v-data-table
                v-model="selectedItems"
                :loading="dataLoading"
                loading-text="Loading... Please wait"
                item-key="id"
                :disable-sort="true"
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="itemsTotal"
                :search="search"
                :sort-desc="[false, true]"
                multi-sort
                show-select
                class="elevation-5 mb-15 align-center"
                :footer-props="{
                  itemsPerPage: [10]
                }"
              >
                <template v-slot:item.actions="{ item }">
                  <span class="group">
                    <v-icon class="pa-2" @click="editItem(item)">mdi-playlist-edit</v-icon>
                    <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
    <v-dialog v-model="dialog" width="1000">
      <v-card>
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-text-field
                    filled
                    v-model="editedItem.name"
                    label="Name"
                    :rules="[v => !!v || 'Name is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="editedItem.description"
                    label="Description"
                    :value="editedItem.description"
                    :rules="[v => !!v || 'Description is required']"
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="back-to-blue mb-3 ml-5" rounded @click="dialog = false">
            cancel
          </v-btn>
          <v-btn class="mb-3 mr-3 ms-auto" color="secondary" rounded @click="saveItem(editedItem)">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import CoursesService from "../services/courses.service";

export default {
  name: "ManageCategories",
  components: {
    Header
  },
  data() {
    return {
      dialog: false,
      options: {},
      search: "",
      breadcrumbsItems: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Manage Categories",
          disabled: true,
          href: "/managecategories"
        }
      ],
      selectedItems: [],
      editedItem: {
        name: "",
        description: ""
      },
      originalItem: {
        name: "",
        description: ""
      },
      defaultlItem: {
        name: "",
        description: ""
      },
      items: [],
      isLoading: false,
      deleteReportId: null,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      editedIndex: -1
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    selectedItems: {
      handler(val) {
        if (val.length > 0) this.deleteBtnDisabled = false;
      },
      deep: true
    },

    options: {
      async handler() {
        if (this.lastPage !== null) {
          this.getItems();
        }
      },
      deep: true
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    formTitle: {
      get() {
        return this.editedIndex === -1 ? "Create New Category" : "Edit Category";
      },
      set(newTitle) {
        return newTitle;
      }
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  methods: {

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getItems();
    },

    async getItems() {
      this.dataLoading = true;

      const response = await CoursesService.getCategories();
     

      this.items = response.data.data.slice();
      this.dataLoading = false;
    },

    editItem(item) {
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveItem(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          // update item
          const data = {
            name: item.name,
            description: item.description
          };

          const response = await CoursesService.updateCategory({
            id: item.id,
            data: data
          });
          this.items.splice(this.editedIndex, 1, response.data.data);
          this.showSnackbar("The Category has been updated successfully");
        } else {
          // create item

          const data = {
            name: item.name,
            description: item.description
          };

          const response = await CoursesService.createCategory({
            data: data
          });

          this.items.push(response.data.data);
          this.showSnackbar("The Category has been added successfully");
        }
      }
      this.dialog = false;
      this.$refs.form.reset();
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this category?") &&
        this.items.splice(index, 1);

      await CoursesService.removeCategory({
        id: item.id
      });

      this.showSnackbar(item.name + " has been deleted");
    }
  }
};
</script>
<style lang="scss" scoped>

.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #C5E1E9 !important;
  color: #2F91AE;
}
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
</style>
